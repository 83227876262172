import { useShoppingCart } from "@sushicorp/contexts";
import { useFetchUser } from "@sushicorp/services";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

import useAuth from "contexts/auth/auth.context.hooks";
import { useRebuildShoppingCart } from "hooks/useRebuildShoppingCart";
import { notify } from "utils/common.utils";
import { createErrorNotification } from "utils/notifications.utils";

const useListenRebuildShoppingCart = () => {
  const { isAnonymousCartEmpty, isSameCart } = useRebuildShoppingCart();
  const { emptyCartHandler } = useRebuildShoppingCart();
  const { fillCartWithAnonCartProducts } = useRebuildShoppingCart();
  const { validateShoppingCartOnRefill } = useRebuildShoppingCart();
  const { shoppingCart: cart, setAnonymousShoppingCart } = useShoppingCart();
  const { setIsRebuilding } = useShoppingCart();
  const auth = useAuth();
  const { data: user, isFetched } = useFetchUser(auth, notify);
  const { replace } = useRouter();
  const [rebuildInitialized, setRebuildInitialized] = useState(false);
  const [productsHasBeenAdded, setProductsHasBeenAdded] = useState(false);
  const { isAnonymous } = auth;
  const isSavedUser = !isAnonymous && isFetched && !!user;

  const handleRebuildCart = useCallback(async () => {
    if (rebuildInitialized) return;

    try {
      setRebuildInitialized(true);
      await emptyCartHandler();
      await fillCartWithAnonCartProducts();
      setProductsHasBeenAdded(true);
    } catch (e) {
      createErrorNotification(e.message);
      await emptyCartHandler();
      setIsRebuilding(false);
      setAnonymousShoppingCart(undefined);
      setProductsHasBeenAdded(false);
      replace("/categories");
    }
  }, [
    emptyCartHandler,
    fillCartWithAnonCartProducts,
    setAnonymousShoppingCart,
    setIsRebuilding,
    rebuildInitialized,
    replace
  ]);

  const handleValidateCartOnRefill = useCallback(async () => {
    setProductsHasBeenAdded(false);

    try {
      await validateShoppingCartOnRefill();
    } catch (e) {
      createErrorNotification(e.message);
      await emptyCartHandler();
      replace("/categories");
    } finally {
      setIsRebuilding(false);
      setAnonymousShoppingCart(undefined);
      setRebuildInitialized(false);
    }
  }, [
    emptyCartHandler,
    setAnonymousShoppingCart,
    validateShoppingCartOnRefill,
    replace,
    setIsRebuilding
  ]);

  useEffect(() => {
    if (!isSavedUser || isAnonymousCartEmpty || isSameCart) return;

    setIsRebuilding(true);
    handleRebuildCart();
  }, [
    isSavedUser,
    isAnonymousCartEmpty,
    isSameCart,
    handleRebuildCart,
    setIsRebuilding
  ]);

  useEffect(() => {
    if (!productsHasBeenAdded) return;
    if (!cart || !Object.keys(cart.stores).length) return;

    handleValidateCartOnRefill();
  }, [cart, productsHasBeenAdded, handleValidateCartOnRefill]);
};

export default useListenRebuildShoppingCart;
