import { useGeo, useStores } from "@sushicorp/contexts";
import { useShoppingCart as useShoppingCartContext } from "@sushicorp/contexts";
import { buildArtisnHeaders } from "@sushicorp/services";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { validateShoppingCart } from "artisn/shopping-cart";
import { useCallback, useMemo } from "react";
import invariant from "tiny-invariant";

import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";

const { ARTISN, API } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;
const { API_URL } = API;

export const useRebuildShoppingCart = () => {
  const { emptyShoppingCart, addProduct } = useShoppingCart();
  const { anonymousShoppingCart: anonCart } = useShoppingCartContext();
  const { shoppingCart: cart } = useShoppingCartContext();
  const { selectedStore } = useStores();
  const { selectedCoordinates } = useGeo();
  const { lat, lng } = selectedCoordinates ?? {};
  const auth = useAuth();
  const { isAnonymous, uid } = auth;
  const noAnonStores = !!anonCart && Object.keys(anonCart.stores).length === 0;
  const isAnonymousCartEmpty = !anonCart || !anonCart.stores || noAnonStores;
  const isSameCart = cart?.id === anonCart?.id;
  const anonCartProducts = useMemo(
    () => (!isAnonymousCartEmpty ? getShoppingCartProducts(anonCart) : []),
    [isAnonymousCartEmpty, anonCart]
  );

  const emptyCartHandler = useCallback(async () => {
    try {
      const { id: cartId } = cart ?? {};
      if (!cartId) return;
      await emptyShoppingCart?.();
    } catch (e) {
      throw new Error("No pudimos vaciar tu carrito");
    }
  }, [cart, emptyShoppingCart]);

  const fillCartWithAnonCartProducts = useCallback(async () => {
    invariant(!!addProduct, "addProduct no está definido");
    invariant(
      selectedStore,
      "Debe existir una tienda para añadir productos al carrito"
    );

    for (const product of anonCartProducts) {
      try {
        await addProduct({
          product,
          amount: product.amount,
          comment: product.comment
        });
      } catch (e) {
        throw new Error(e.message);
      }
    }
  }, [addProduct, anonCartProducts, selectedStore]);

  const validateShoppingCartOnRefill = useCallback(async () => {
    if (!uid) throw new Error("No hay usuario para validar");
    if (!cart) throw new Error("No hay carrito para validar");
    if (!Object.keys(cart.stores).length) {
      throw new Error(
        "Ha ocurrido un error de validación. Se vaciará el carrito"
      );
    }
    if (!selectedStore) throw new Error("No hay tienda seleccionada");

    const headers = await buildArtisnHeaders();
    const validation = await validateShoppingCart(
      {
        accountId: ACCOUNT_ID,
        anonymous: !!isAnonymous,
        customerId: uid,
        apiURL: API_URL,
        latitude: lat,
        longitude: lng,
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME
      },
      headers
    );
    const { stores: storeAlerts = [] } = validation ?? {};
    const { products: productAlerts = [] } = validation ?? {};
    const hasStoreAlerts = storeAlerts.length > 0;
    const hasProductAlerts = productAlerts.length > 0;
    if (hasStoreAlerts) {
      throw new Error(
        "La nueva ubicación no puede atender tu pedido. Se vaciará el carrito."
      );
    }
    if (hasProductAlerts) {
      throw new Error(
        "La nueva ubicación no tiene los productos del carrito. Se vaciará el carrito."
      );
    }
  }, [cart, isAnonymous, selectedStore, uid, lat, lng]);

  return useMemo(
    () => ({
      anonCartProducts,
      isAnonymousCartEmpty,
      isSameCart,
      emptyCartHandler,
      fillCartWithAnonCartProducts,
      validateShoppingCartOnRefill
    }),
    [
      anonCartProducts,
      isAnonymousCartEmpty,
      isSameCart,
      emptyCartHandler,
      fillCartWithAnonCartProducts,
      validateShoppingCartOnRefill
    ]
  );
};
