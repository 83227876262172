import React from "react";

import { ShoppingCartSummaryPlaceholderStyled as Styles } from "./ShoppingCartSummary.styles";
import { ShoppingCartSummaryPlaceholderProps as Props } from "./ShoppingCartSummary.types";

const ShoppingCartSummaryPlaceholder: React.FC<Props> = props => {
  return (
    <Styles className="ShoppingCartSummaryPlaceholder">
      <div className="ShoppingCartSummaryPlaceholder__wrapper loading-shine" />
    </Styles>
  );
};

ShoppingCartSummaryPlaceholder.defaultProps = {};

export default ShoppingCartSummaryPlaceholder;
