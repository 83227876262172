import useListenAddresses from "./listeners/useListenAddresses";
import useListenBillingData from "./listeners/useListenBillingData";
import useListenCatalogue from "./listeners/useListenCatalogue";
import useListenGeo from "./listeners/useListenGeo";
import useListenGlobals from "./listeners/useListenGlobals";
import useListenPayments from "./listeners/useListenPayments";
import useListenRebuildShoppingCart from "./listeners/useListenRebuildShoppingCart";
import useListenStores from "./listeners/useListenStores";
import useListenUser from "./listeners/useListenUser";
import useListenVendor from "./listeners/useListenVendor";

const useListeners = () => {
  useListenUser();
  useListenGeo();
  useListenStores();
  useListenVendor();
  useListenAddresses();
  useListenBillingData();
  useListenPayments();
  useListenCatalogue();
  useListenGlobals();
  useListenRebuildShoppingCart();
};

export default useListeners;
