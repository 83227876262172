import { Image } from "artisn-ui-react";
import React, { cloneElement, useEffect } from "react";

import Styles from "./InfoActionModal.styles";
import { InfoActionModalProps as Props } from "./InfoActionModal.types";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";

import ArrowLeftLargeSVG from "../../../../public/assets/images/arrow-left-large.svg";
import CloseSVG from "../../../../public/assets/images/close.svg";

const InfoActionModal: React.FC<Props> = props => {
  const { className, icon, title, description, opened } = props;
  const { isTransparent = false } = props;
  const { cancelAction, confirmAction, cancelText, confirmText } = props;
  const { onClose, children, hideTitle, showGoBackIcon, onGoBack } = props;
  const { isRating, hideButtons, disabled } = props;
  const { closeOnClickOutside = true } = props;
  const color = isTransparent ? "transparent" : "white";

  if (!cancelAction && !confirmAction) {
    throw new Error(
      `${InfoActionModal.name}: You must provide at least one action for this modal`
    );
  }

  const renderIcon = () => {
    if (!icon) return null;
    if (typeof icon === "string") {
      return (
        <Image
          className="InfoActionModal__img"
          image={icon}
          alt="Action icon"
          placeholder="blur"
        />
      );
    }
    return cloneElement(icon, {
      className: "InfoActionModal__img"
    });
  };

  useEffect(() => {
    if (!opened) return;
    document.body.style.overflow = "hidden";
    document.body.style.position = "static";
  }, [opened]);

  return (
    <Modal
      color={color}
      opened={opened}
      noDefaultDimensions
      closeIcon={cancelAction ? <CloseSVG /> : undefined}
      onClose={onClose}
      closeOnClickOutside={closeOnClickOutside}
      blockScroll
      backdropConfig={{
        className: "InfoActionModal__backdrop"
      }}
    >
      <Styles
        className={`InfoActionModal ${className}`}
        hasIcon={!!icon}
        isRating={isRating}
        hideButtons={hideButtons}
      >
        {showGoBackIcon ? (
          <button className="InfoActionModal__go-back" onClick={onGoBack}>
            <ArrowLeftLargeSVG />
          </button>
        ) : null}
        {renderIcon()}
        {!hideTitle ? (
          <h3 className="InfoActionModal__title">{title}</h3>
        ) : null}
        {description ? (
          <p className="InfoActionModal__description">{description}</p>
        ) : null}
        {children}
        {!isRating && (
          <div className="InfoActionModal__buttons">
            {cancelAction ? (
              <Button
                className="InfoActionModal__button InfoActionModal__cancel-button"
                color="primary"
                type="BORDER"
                onClick={cancelAction}
                disabled={disabled}
              >
                {cancelText ?? "Cancel"}
              </Button>
            ) : null}
            {confirmAction ? (
              <Button
                className="InfoActionModal__button InfoActionModal__confirm-button"
                type="FILLED"
                color="primary"
                onClick={confirmAction}
                disabled={disabled}
              >
                {confirmText ?? "Confirm"}
              </Button>
            ) : null}
          </div>
        )}
        {isRating && !hideButtons && (
          <div className="RateModal__buttons">
            {cancelAction ? (
              <Button
                className="RateModal__buttons__button RateModal__buttons__cancel-button"
                type="BORDER"
                color="primary"
                onClick={cancelAction}
              >
                {cancelText ?? "Cancel"}
              </Button>
            ) : null}
            {confirmAction ? (
              <Button
                className="RateModal__buttons__button RateModal__buttons__confirm-button"
                onClick={confirmAction}
                color="primary"
                disabled={disabled}
              >
                {confirmText ?? "Confirm"}
              </Button>
            ) : null}
          </div>
        )}
      </Styles>
    </Modal>
  );
};

InfoActionModal.defaultProps = {
  className: ""
};

export default InfoActionModal;
